<template>
    <div>
        <el-table style="margin:10px 0" :data="tableData" border :height="tableHeight">
            <el-table-column label="序号" width="50" type="index" />
            <el-table-column prop="name" label="客户姓名" width="100" />
            <el-table-column label="客户资质" width="190">
                <template slot-scope="scope">
                    <span
                        v-if="scope.row.hasHouse == 1"
                        class="icon house"
                        style="margin-right: 10px"
                    >房</span>
                    <span
                        v-if="scope.row.hasVehicle == 1"
                        class="icon car"
                        style="margin-right: 10px"
                    >车</span>
                    <span
                        v-if="scope.row.hasShebao == 1"
                        style="margin-right: 10px"
                        class="icon shebao"
                    >社</span>
                    <span
                        v-if="scope.row.hasGongjijin == 1"
                        class="icon gongjijin"
                        style="margin-right: 10px"
                    >金</span>
                    <span
                        v-if="scope.row.hasBaodan == 1"
                        style="margin-right: 10px"
                        class="icon baodan"
                    >保</span>
                </template>
            </el-table-column>
            <el-table-column label="资金需求" width="200">
                <template slot-scope="scope">
                    <span>{{ scope.row.fundNeeds }}万</span>
                </template>
            </el-table-column>
            <el-table-column prop="tag" label="无效类型" />
            <el-table-column prop="createdTime" label="确认无效时间" />
            <!-- <el-table-column prop="source" label="来源渠道"></el-table-column> -->
            <!-- <el-table-column label="是否计入条数">
        <template slot-scope="scope">
          <span v-if="scope.row.counted == 0">否</span>
          <span v-if="scope.row.counted == 1">是</span>
        </template>
            </el-table-column>-->
            <!-- <el-table-column
        prop="createdTime"
        label="线索分配时间"
            ></el-table-column>-->
            <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="primary"
                        @click="handleEdit(scope.$index, scope.row)"
                    >查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block" style="text-align: right">
            <el-pagination
                :current-page="page"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="len"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>

<script>
import { clue_logs } from '@/api/my_clue';
import { getYMDHMS } from '@/assets/js/time.js';
export default {
    props: ['time', 'keyWord'],
    data() {
        return {
            tableData: [],
            status: {
                startTime: this.time[0] / 1000,
                endTime: this.time[1] / 1000,
                category: 5,
                pageNo: '',
                pageSize: '',
            },
            page: 1,
            len: 0,
            size: 10,
            tableHeight: window.innerHeight - 270,
        };
    },
    watch: {
        //监听时间的变化从而渲染页面
        time(newName) {
            if (newName) {
                this.status.startTime = newName[0] / 1000;
                this.status.endTime = newName[1] / 1000;
                // this.xr(this.status);
                // this.page = 1
                // this.size = 10
                this.handleCurrentChange(1);
            } else {
                this.status.startTime = '';
                this.status.endTime = '';
                // this.xr(this.status)
                // this.page = 1
                // this.size = 10
                this.handleCurrentChange(1);
            }
        },
        keyWord(val) {
            this.status.keyword = val;
            this.handleCurrentChange(1);
        },
    },
    // 窗体大小变化就执行getHeight方法即可
    created() {
        window.addEventListener('resize', this.getHeight);
    },
    mounted() {
        //页面初始渲染
        this.status.pageNo = this.page;
        this.status.pageSize = this.size;
        this.xr();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getHeight);
    },
    methods: {
        getHeight() {
            this.tableHeight =
                this.tableData.length > 0 ? window.innerHeight - 270 : '';
        },
        //渲染页面
        xr() {
            clue_logs(this.status).then((res) => {
                if (res.code == 0) {
                    this.tableData = res.data.list;
                    this.len = res.data.total;
                    let clueInvalidTag =
                        this.common.allValueNeed('clue-invalid-tag');
                    this.tableData.forEach((item) => {
                        console.log('clueInvalidTag', clueInvalidTag);
                        console.log('item', item.tag);
                        // if (item.tag == clueInvalidTag.number) {
                        //   console.log(clueInvalidTag.cnVal);
                        // }
                        clueInvalidTag.forEach((valItem) => {
                            if (item.tag == valItem.number) {
                                item.tag = valItem.cnVal;
                            }
                        });
                    });
                    console.log('1111111111111111111', this.tableData);
                    // for (let i = 0; i < this.tableData.length; i++) {
                    //   let c = clueInvalidTag.find((item) => {
                    //     return this.tableData[i].tag == item.number;
                    //   });
                    //   this.tableData[i].tag = c.cnVal;
                    // }
                    for (let i = 0; i < this.tableData.length; i++) {
                        this.tableData[i].createdTime = getYMDHMS(
                            this.tableData[i].createdTime
                        );
                    }
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //客户资质标签选择
        handleEdit(index, row) {
            this.tk = true;
            this.$emit('showDetail', true, row);
        },
        //分页
        handleSizeChange(val) {
            this.size = val;
            this.status.pageSize = this.size;
            this.xr();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.status.pageNo = this.page;
            this.xr();
        },
    },
};
</script>

<style lang="less" scoped>
@import '@/css/mini-table.less';
/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #909399;
}
</style>
